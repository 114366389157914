/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.create-well {
  background: rgba(0, 0, 0, 0.01);
  border-radius: 5px;
  box-shadow: inset 0 0 1px black, 0 0 10px black;
  padding: 10px;
}

.btn-group.special {
  display: flex;
}

.special .btn {
  flex: 1
}

@media (max-width: 768px) {
  .btn, .btn-group {
      width: 100%;
  }
}

.processingSpinner {
  width: 3rem;
  height: 3rem;
}

.processingSpinnerText {
  font-size: 2rem;
}

.hugeFont {
  font-size: 100px;
}

.inherit-width {
  width: inherit;
}

.ticketRow.d-none {
  display: none !important;
}

.mathOperatorIcon {
  font-size: 80px;
}

.statusCards .card {
  border-radius: 0;
}

button.rs-play {
  display: none;
}

.AllDoneIcon {
  font-size: 235px;
}

.invalidInput {
  border-color: #ff0018;
  border-width: 3px;
}

.form-control:focus.invalidInput {
  color: #495057;
  border-color: #ff0018;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.validation_message {
  color: #ff0018
}

.flex-center{
  display: flex;
  justify-content: center;
}
